import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useContext } from 'react';
import Rating from 'react-rating';
import PropTypes from 'prop-types';
import AppContext from 'context/Context';

const DynamicStarRating = ({
  fractions = 2,
  rating,
  handleChange,
  ...rest
}) => {
  const {
    config: { isRTL }
  } = useContext(AppContext);
  const [hoveredRating, setHoveredRating] = useState(0);

  const getRatingColor = value => {
    if (value === 1) return '#ff3622'; // Red
    if (value === 2) return '#ff8623'; // Orange
    if (value === 3) return '#fece00'; // Yellow
    if (value === 4) return '#73cf12'; // Light Green
    if (value === 5) return '#00A84D'; // Green
    return '#000000'; // Default color (black
  };

  return (
    <Rating
      fractions={fractions}
      initialRating={rating}
      fullSymbol={
        <FontAwesomeIcon
          icon="star"
          style={{ color: getRatingColor(hoveredRating || rating) }}
        />
      }
      emptySymbol={<FontAwesomeIcon icon="star" className="text-300" />}
      onChange={handleChange}
      onHover={rate => setHoveredRating(rate)}
      onLeave={() => setHoveredRating(0)}
      direction={isRTL ? 'rtl' : 'ltr'}
      {...rest}
    />
  );
};

DynamicStarRating.propTypes = {
  fractions: PropTypes.number,
  rating: PropTypes.number.isRequired,
  handleChange: PropTypes.func
};

export default DynamicStarRating;
